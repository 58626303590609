<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Objet / sous-objet" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail d'un object</div>
              </v-row>
            </v-card-title>

            <!-- l'application -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">application</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <div class="my-4">{{ appName }} </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>            

            <!-- service -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">service</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <div class="my-4" >{{ serviceName }} </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>    

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
       
            <!-- Les sous-objets -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="label-field capitalize">les sous-objets</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <!-- Affichage des sous-objets à associé -->
                  <div v-for="(sub, i) in associateSubobjects" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <v-text-field 
                        placeholder="Le nom du sous-objet"
                        dense
                        v-model="sub.label">
                      </v-text-field>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateSubObject(sub)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des sous-objets associables -->
                  <div>
                    <v-row no-gutters align="start" justify="space-between">
                       <v-text-field 
                        placeholder="Le nom du sous-objet"
                        dense
                        v-model="subobject"                        
                        v-on:keyup.enter="addSubObject()">
                      </v-text-field>
                      <v-btn
                        color="primary"
                        icon
                        @click="addSubObject()"
                        :disabled="!subobject"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";
import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

// Le service qui n'en est pas un
const _SERVICE_UNDEFINED = {id: "undefined", digitalName: "PAS DE SERVICE"};

export default {
  name: "EditTicketingObjectSubObject",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      serviceService: null,
      serviceApps : null,


      /** l'identifiant à éditer */
      entityId: null,
      /** l'identifiant de l'application référente à l'objet */
      appId: null,
      /** l'identifiant du service référent à l'objet */
      serviceId: null,

      /**La donnée originale */
      source: null,

      /** le label */
      label: null,
      /** le nom de l'application */
      appName: null,
      /** le nom du service */
      serviceName: null,

      /** Le sousObjet saisi */
      subobject: null,
      /** les sous-objets associés initialement */
      sourceAssociateSubobjects: [],
      /** les sous-objets associés */
      associateSubobjects: [],
    };
  },
  methods: {
    /** Chargement des éléments de l'objet */
    async load() {
      try {
        this.loading = true;

        // Récupération de l'application
        let app = await this.serviceApps.getById(this.appId);

        let serv = null;
        // Récupération du service
        if (this.serviceId == _SERVICE_UNDEFINED.id) {
          serv = _SERVICE_UNDEFINED;
        } else {
          serv = await this.serviceService.getServiceById(this.serviceId);
        }

        // Récupération de l'objet cible
        let entity = await this.service.getObjectById(this.appId, this.serviceId, this.entityId);
        this.source = JSON.parse(JSON.stringify(entity));
        this.source.app = app;
        this.source.service = serv;

        // Récupération de tous les sous-objets
        this.associateSubobjects = await this.service.getAllSubobjects(this.appId, this.serviceId, this.entityId);
        for (let i = 0; i < this.associateSubobjects.length; i++) {
          this.associateSubobjects[i].initial = this.associateSubobjects[i].label; 
        }
        this.sourceAssociateSubobjects = [...this.associateSubobjects];

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /**initialiser les données à partir de l'objet source */
    init() {
      this.label = this.source.label;
      this.appName = this.source.app.label; 
      this.serviceName = this.source.service.digitalName;
    },

    /** Sauvegarde de tout l'objet, sous-objet  */
    async save() {      
      let success = true;
      
      try {
        // enregistrement de l'entity
        if (this.entityHasChanged) {
          // Fabrication de l'objet à updaté
          let entity = {
            id: this.source.id,
            label: this.label,
          }
          
          // Mise à jour de l'objet
          await this.service.updateObject(this.appId, this.serviceId, entity);
        }

        // Sauvegarde des sousobjet
        if (this.subobjectsHasChanged) {
          // Sauvegarde de tous les ajout / modif de sousobjets
          for (let i = 0; i < this.associateSubobjects.length; i++) {
            let sub = this.associateSubobjects[i];

            // sousobjet nouveau ??
            if (!sub.id) {
              success = this.createSubobject(sub);
              if (!success) { break; }

            } else if (sub.initial != sub.label) {
              success = this.updateSubobject(sub);
              if (!success) { break; }
            }
          }
          if (success) {
            // Sauvegarde de toutes les supp de sousobjet
            for (let i = 0; i < this.sourceAssociateSubobjects.length; i++) {            
              let found = this.associateSubobjects.find((s) => s.id == this.sourceAssociateSubobjects[i].id);
              
              if (!found) {
                success = await this.deleteSubobject(this.sourceAssociateSubobjects[i]);
              }
            }
          }
        }
        
        if (success) {
          this.disableAlertQuit();
          this.$router.go(-1);
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement du statut : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }   
    },

    /** Création d'un sous-objet associé à cet objet */
    async createSubobject(sub) {
      let saveOk = true;

      try {
        let entity = {
          label: sub.label,
        };
        await this.service.createSubObject(this.appId, this.serviceId, this.entityId, entity);

      } catch (error) {
        console.error("Create subobject error : " + error);
        saveOk = false;
      }
      return saveOk;
    },
    /** Met a jour un sousobjet en base */
    async updateSubobject(sub) {
      let saveOk = true;

      try {
        let entity = {
          id: sub.id,
          label: sub.label,
        };
        await this.service.updateSubobject(this.appId, this.serviceId, this.entityId, entity);

      } catch (error) {
        console.error("Update subobject error : " + error);
        saveOk = false;
      }

      return saveOk;
    },

    /** suppression d'un sousobjet en base */
    async deleteSubobject(sub) {
      let saveOk = true;

      try {
        if (sub.id) {
          await this.service.deleteSubobject(this.appId, this.serviceId, this.entityId, sub.id);
        }
      } catch (error) {
        console.error("Delete subobject error : " + error);
        saveOk = false;
      }
      return saveOk;
    },

    /** Suppression d'un sous-objet */
    removeAssociateSubObject(subOject) {
      this.associateSubobjects.splice(
        this.associateSubobjects.findIndex((s) => s.label == subOject.label),
        1
      );
    },

    /** Ajouter le sous-objet à la liste */
    addSubObject() {
      this.associateSubobjects.push({
        label: this.subobject,
        initial: "",
      });
      this.subobject = null;
    },



  },
  computed: {
    completed() {
      if (!this.label) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }

      if (this.subobjectsHasChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }     

      return changed;
    },

    /** Retourne true si :
     * - le label d'un des sous-objet a changé
     * - un sous-objet a été ajouté
     * - un sous-objet a été supprimé
     */
    subobjectsHasChanged() {
      let changed = false;

      // Recherche des nouveaux sous-objets
      this.associateSubobjects.forEach((sa) => {
        let found = this.sourceAssociateSubobjects.find((sb) => sa.label == sb.label);
        if (!found) {
          changed = true;
        }
      });

       /** Pour éviter d'analyser la fonction inverse précédente, si on a déjà détecté du changement, on le retourne */
      if (changed) {
        return true;
      }

      // Recherche des sous-objets supprimés
      this.sourceAssociateSubobjects.forEach((sa) => {
        let found = this.associateSubobjects.find((sb) => sa.label == sb.label);
        if (!found) {
          changed = true;
        }
      });

      /** Pour éviter d'analyser la fonction inverse précédente, si on a déjà détecté du changement, on le retourne */
      if (changed) {
        return true;
      }
      
      // Analyse du changement des labels sur les sous-objets
      this.associateSubobjects.forEach((s) => {
        if (s.label !== s.initial) {
          changed = true;
        }
      });

      return changed;
    }

  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new TicketingObjectSubobjectService(this.$api.getTicketingObjectSubobject());
    this.serviceService = new ServicesService(this.$api);
    this.serviceApps = new ApplicationService(this.$api.getApplicationApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }
    // Récupération des paramètres de la route de l'objet
    if (this.$route.query) {
      if (this.$route.query.app) {
        this.appId = this.$route.query.app;
      }
      if (this.$route.query.service) {
        this.serviceId = this.$route.query.service;
      }
    }

    this.load();
  },
};
</script>

<style>
</style>